import React from 'react';
import { Route, Switch } from "react-router-dom";
import Loadable from 'react-loadable';
import PrivateRoute from '../../components/PrivateRoute';

const loading = () => <div></div>;

const Home = Loadable({
  loader: () => import(/* webpackChunkName: "Home" */ '../../pages/Home'),
  loading,
  modules: ['Home'],
});
const NotFound = Loadable({
  loader: () => import(/* webpackChunkName: "NotFound" */ '../../pages/NotFound'),
  loading,
  modules: ['Home'],
});
const BuyVignette = Loadable({
  loader: () => import(/* webpackChunkName: "BuyVignette" */ '../../pages/BuyVignette'),
  loading,
  modules: ['BuyVignette'],
});
const CheckVignette = Loadable({
  loader: () => import(/* webpackChunkName: "CheckVignette" */ '../../pages/CheckVignette'),
  loading,
  modules: ['CheckVignette'],
});
const AnnualVignette = Loadable({
  loader: () => import(/* webpackChunkName: "AnnualVignette" */ '../../pages/VignetteTypes/AnnualVignette'),
  loading,
  modules: ['AnnualVignette'],
});
const WeeklyVignette = Loadable({
  loader: () => import(/* webpackChunkName: "WeeklyVignette" */ '../../pages/VignetteTypes/WeeklyVignette'),
  loading,
  modules: ['WeeklyVignette'],
});
const WeekendVignette = Loadable({
  loader: () => import(/* webpackChunkName: "WeekendVignette" */ '../../pages/VignetteTypes/WeekendVignette'),
  loading,
  modules: ['WeekendVignette'],
});
const MonthlyVignette = Loadable({
  loader: () => import(/* webpackChunkName: "MonthlyVignette" */ '../../pages/VignetteTypes/MonthlyVignette'),
  loading,
  modules: ['MonthlyVignette'],
});
const QuarterlyVignette = Loadable({
  loader: () => import(/* webpackChunkName: "QuarterlyVignette" */ '../../pages/VignetteTypes/QuarterlyVignette'),
  loading,
  modules: ['QuarterlyVignette'],
});
const BuyingVignetteSteps = Loadable({
  loader: () => import(/* webpackChunkName: "BuyingVignetteSteps" */ '../../pages/BuyingVignetteSteps'),
  loading,
  modules: ['BuyingVignetteSteps'],
});
const ReturnOBU = Loadable({
  loader: () => import(/* webpackChunkName: "ReturnOBU" */ '../../pages/ReturnOBU'),
  loading,
  modules: ['ReturnOBU'],
});
const RegisterSteps = Loadable({
  loader: () => import(/* webpackChunkName: "RegisterSteps" */ '../../pages/RegisterSteps'),
  loading,
  modules: ['RegisterSteps'],
});
const DownloadDocuments = Loadable({
  loader: () => import(/* webpackChunkName: "DownloadDocuments" */ '../../pages/DownloadDocuments'),
  loading,
  modules: ['DownloadDocuments'],
});
const Prices = Loadable({
  loader: () => import(/* webpackChunkName: "Prices" */ '../../pages/Prices'),
  loading,
  modules: ['Prices'],
});
const Register = Loadable({
  loader: () => import(/* webpackChunkName: "Register" */ '../../pages/Register'),
  loading,
  modules: ['Register'],
});
const AboutEVignette = Loadable({
  loader: () => import(/* webpackChunkName: "AboutEVignette" */ '../../pages/AboutEVignette'),
  loading,
  modules: ['AboutEVignette'],
});
const AboutRoutePass = Loadable({
  loader: () => import(/* webpackChunkName: "AboutRoutePass" */ '../../pages/AboutEVignette/AboutRoutePass'),
  loading,
  modules: ['AboutRoutePass'],
});
const AboutTheTollSystem = Loadable({
  loader: () => import(/* webpackChunkName: "AboutTheTollSystem" */ '../../pages/AboutEVignette/AboutTheTollSystem'),
  loading,
  modules: ['AboutTheTollSystem'],
});
const AboutDelicts = Loadable({
  loader: () => import(/* webpackChunkName: "AboutDelicts" */ '../../pages/AboutEVignette/AboutDelicts'),
  loading,
  modules: ['AboutDelicts'],
});
const FAQ = Loadable({
  loader: () => import(/* webpackChunkName: "FAQ" */ '../../pages/FAQ/FAQVignette'),
  loading,
  modules: ['FAQVignette'],
});
const FAQtoll = Loadable({
  loader: () => import(/* webpackChunkName: "FAQtoll" */ '../../pages/FAQ/FAQtoll'),
  loading,
  modules: ['FAQtoll'],
});
const FAQDelicts = Loadable({
  loader: () => import(/* webpackChunkName: "FAQDelicts" */ '../../pages/FAQ/FAQDelicts'),
  loading,
  modules: ['FAQDelicts'],
});
const FAQMobile = Loadable({
  loader: () => import(/* webpackChunkName: "FAQMobile" */ '../../pages/FAQ/FAQMobile'),
  loading,
  modules: ['FAQMobile'],
});
const AboutDigiToll = Loadable({
    loader: () => import(/* webpackChunkName: "AboutDigiToll" */ '../../pages/AboutDigiToll'),
    loading,
    modules: ['AboutDigiToll'],
});
const News = Loadable({
    loader: () => import(/* webpackChunkName: "News" */ '../../pages/News'),
    loading,
    modules: ['News'],
});
const NewsPage = Loadable({
    loader: () => import(/* webpackChunkName: "News" */ '../../pages/NewsPage'),
    loading,
    modules: ['NewsPage'],
});
const Contacts = Loadable({
  loader: () => import(/* webpackChunkName: "Contacts" */ '../../pages/Contacts'),
  loading,
  modules: ['Contacts'],
});
//const RoadInformation = Loadable({
  //loader: () => import(/* webpackChunkName: "RoadInformation" */ '../../pages/RoadInformation'),
  //loading,
  //modules: ['RoadInformation'],
//});
const Partners = Loadable({
  loader: () => import(/* webpackChunkName: "Partners" */ '../../pages/Partners'),
  loading,
  modules: ['Partners'],
});
const Login = Loadable({
  loader: () => import(/* webpackChunkName: "Login" */ '../../pages/Login'),
  loading,
  modules: ['Login'],
});
const UserHome = Loadable({
    loader: () => import(/* webpackChunkName: "UserHome" */ '../../pages/Account/Home'),
    loading,
    modules: ['UserHome'],
});
const PaymentForm = Loadable({
    loader: () => import(/* webpackChunkName: "PaymentForm" */ '../../pages/PaymentForm'),
    loading,
    modules: ['PaymentForm'],
});
const SubscribeVignetteExpirationForm = Loadable({
  loader: () => import(/* webpackChunkName: "Terms" */ '../../pages/NotifyForVignetteExpiration'),
  loading,
  modules: ['SubscribeVignetteExpirationForm'],
});
const NotificationsUnsubscribe = Loadable({
  loader: () => import(/* webpackChunkName: "Terms" */ '../../pages/NotificationsUnsubscribe'),
  loading,
  modules: ['NotificationsUnsubscribe'],
});
const Terms = Loadable({
  loader: () => import(/* webpackChunkName: "Terms" */ '../../pages/TermsAndConditions'),
  loading,
  modules: ['Terms'],
});
const FailTransaction = Loadable({
  loader: () => import(/* webpackChunkName: "FailTransaction" */ '../../pages/FailedTransaction'),
  loading,
  modules: ['FailTransaction'],
});
const FailActivation = Loadable({
  loader: () => import(/* webpackChunkName: "FailActivation" */ '../../pages/FailedActivation'),
  loading,
  modules: ['FailActivation'],
});
const PrivacyPolicy = Loadable({
  loader: () => import(/* webpackChunkName: "PrivacyPolicy" */ '../../pages/PrivacyPolicy'),
  loading,
  modules: ['PrivacyPolicy'],
});
const CookiePolicy = Loadable({
  loader: () => import(/* webpackChunkName: "CookiePolicy" */ '../../pages/CookiePolicy'),
  loading,
  modules: ['CookiePolicy'],
});
const UserConfirm = Loadable({
  loader: () => import(/* webpackChunkName: "UserConfirm" */ '../../pages/UserConfirm'),
  loading,
  modules: ['UserConfirm'],
});
const PasswordReset = Loadable({
  loader: () => import(/* webpackChunkName: "PasswordReset" */ '../../pages/PasswordReset'),
  loading,
  modules: ['PasswordReset'],
});
const PasswordConfirm = Loadable({
  loader: () => import(/* webpackChunkName: "PasswordConfirm" */ '../../pages/PasswordConfirm'),
  loading,
  modules: ['PasswordConfirm'],
});
const RoutePass = Loadable({
  loader: () => import(/* webpackChunkName: "RoutePass" */ '../../pages/RoutePass'),
  loading,
  modules: ['RoutePass'],
});
const Delicts = Loadable({
  loader: () => import(/* webpackChunkName: "Delicts" */ '../../pages/Delicts'),
  loading,
  modules: ['Delicts'],
});
const DelictPayment = Loadable({
  loader: () => import(/* webpackChunkName: "DelictPayment" */ '../../pages/Delicts/DelictPayment'),
  loading,
  modules: ['DelictPayment'],
});
const Cart = Loadable({
  loader: () => import(/* webpackChunkName: "Cart" */ '../../pages/Cart'),
  loading,
  modules: ['Cart'],
});
const Success = Loadable({
  loader: () => import(/* webpackChunkName: "Success" */ '../../pages/Success'),
  loading,
  modules: ['Success'],
});
const TrafficAnnouncement = Loadable({
  loader: () => import(/* webpackChunkName: "TrafficAnnouncement" */ '../../pages/TrafficAnnouncement'),
  loading,
  modules: ['TrafficAnnouncement'],
});
const VehicleRoute = Loadable({
  loader: () => import(/* webpackChunkName: "TrafficAnnouncement" */ '../../pages/VehicleRoute'),
  loading,
  modules: ['VehicleRoute'],
});

const AppRouter = function({ match }) {
  return (
    <Switch>
      <Route path={`${match.url}/`} exact component={Home} />
      <Route path={`${match.url}/buy-vignette`} component={BuyVignette} />
      <Route path={`${match.url}/check-for-validity-of-e-vignette`} component={CheckVignette} />
      <Route path={`${match.url}/annual-e-vignette`} component={AnnualVignette} />
      <Route path={`${match.url}/weekly-e-vignette`} component={WeeklyVignette} />
      <Route path={`${match.url}/weekend-e-vignette`} component={WeekendVignette} />
      <Route path={`${match.url}/monthly-e-vignette`} component={MonthlyVignette} />
      <Route path={`${match.url}/quarterly-e-vignette`} component={QuarterlyVignette} />
      <Route path={`${match.url}/how-to-buy-e-vignette-from-digitoll`} component={BuyingVignetteSteps} />
      <Route path={`${match.url}/how-to-return-OBU`} component={ReturnOBU} />
      <Route path={`${match.url}/register-steps`} component={RegisterSteps} />
      <Route path={`${match.url}/download-documents`} component={DownloadDocuments} />
      <Route path={`${match.url}/prices`} component={Prices} />
    <PrivateRoute path={`${match.url}/account`} component={UserHome}/>
    <Route path={`${match.url}/payment`} component={PaymentForm}/>
      <Route path={`${match.url}/add-email`} component={SubscribeVignetteExpirationForm}/>
      <Route path={`${match.url}/unsubscribe`} exact component={NotificationsUnsubscribe}/>
      <Route path={`${match.url}/unsubscribe/:token`} exact component={NotificationsUnsubscribe}/>
      <Route path={`${match.url}/register`} component={Register}/>
      <Route path={`${match.url}/login`} component={Login}/>
      <Route path={`${match.url}/about-e-vignette`} component={AboutEVignette} />
      <Route path={`${match.url}/about-route-pass`} component={AboutRoutePass} />
      <Route path={`${match.url}/about-toll-system`} component={AboutTheTollSystem} />
      <Route path={`${match.url}/about-delicts`} component={AboutDelicts} />
      {/*<Route path="/:lang/road-information" component={RoadInformation}/>*/}
      <Route path={`${match.url}/faqVignette`} component={FAQ} />
      <Route path={`${match.url}/faqToll`} component={FAQtoll} />
      <Route path={`${match.url}/faqDelicts`} component={FAQDelicts} />
      <Route path={`${match.url}/faq`} component={FAQMobile} />
      <PrivateRoute path={`${match.url}/buy-route-pass/:id`} component={RoutePass} />
      <Route path={`${match.url}/buy-route-pass`} component={RoutePass} />
      <Route path={`${match.url}/pay-delict`} component={Delicts} />
      <Route path={`${match.url}/delict-payment`} component={DelictPayment} />
      <Route path={`${match.url}/shopping-cart`} component={Cart} />
      <Route path={`${match.url}/success`} component={Success} />
      <Route path={`${match.url}/about-digitoll`} component={AboutDigiToll} />
      <Route path={`${match.url}/news`} exact component={News} />
      <Route path={`${match.url}/news/:slug`} exact component={NewsPage} />
      <Route path={`${match.url}/contacts`} component={Contacts} />
      <Route path={`${match.url}/partners`} component={Partners} />
      <Route path={`${match.url}/terms-and-conditions`} component={Terms} />
      <Route path={`${match.url}/failed-transaction`} component={FailTransaction} />
      <Route path={`${match.url}/failed-activation`} component={FailActivation} />
      <Route path={`${match.url}/privacy-policy`} component={PrivacyPolicy} />
      <Route path={`${match.url}/cookie-policy`} component={CookiePolicy} />
      <Route path={`${match.url}/traffic-announcement`} component={TrafficAnnouncement} />
      <Route path={`${match.url}/user/confirm`} exact component={UserConfirm} />
      <Route path={`${match.url}/user/password-confirm`} exact component={PasswordConfirm} />
      <Route path={`${match.url}/user/forgotten-password`} exact component={PasswordReset} />
      <Route path={`${match.url}/vehicle-route/:accountId/:tollingGroupId/:equipmentID/:plate/:dateParam/:sum/:currency`} component={VehicleRoute} />

      <Route component={NotFound} />
    </Switch>
  );
}

export default AppRouter;