import React, { useRef, useState, useEffect } from 'react';
import { useLanguageContext } from '../../contexts/languageContext';
import { useUserContext } from '../../contexts/userContext';
import { usePopupContext } from '../../contexts/popupContext';
import { useErpContext } from '../../contexts/erpContext';
import Link from '../../components/LinkLng';
import LanguagePicker from '../../components/Header/LanguagePicker';
import LanguageFlag from '../../components/Header/LanguageFlag';
import MobileMenu from '../../components/Header/MobileMenu';
import DesktopMenu from '../../components/Header/DesktopMenu';
import CartPreview from '../../components/Header/CartPreview';
import { LANGUAGES } from '../../util/constants';
import { handleErrorMessage, handleErrors } from '../../util/request';
import { getItem, removeItem } from '../../util/storage';
import { isDelictUser, userHasRole } from '../../util/user'
import { CONFIG } from '../../config';
import {
  closeShift,
  getCashierShiftStatusById,
  checkActiveCashierShifts,
  exportDetailedShipmentsCsv,
  exportSummarizedShipmentsExcel
} from '../Services/posService';
import UserDropdown from './UserDropdown';
import Shift from './Shift';
import {useFeaturesContext} from "../../contexts/featuresContext";

export default function Header() {
  const main = useRef(null);
  const { poses } = useErpContext();
  const { user } = useUserContext();
  const { hasFeature } = useFeaturesContext();
  const { gettext, setLanguage } = useLanguageContext();
  const { popupOpen, popupConfirmOpen, popupMessageOpen, popupSubmit } = usePopupContext();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [mobileLangOpen, setMobileLangOpen] = useState(false);
  const anOpenedShift = getItem('cashier-shift');
  const [isShiftOpen, setIsShiftOpen] = useState(anOpenedShift);
  const error = useRef(undefined);
  const { userIs, userAllowed, roleList } = useErpContext();
  const isToll = CONFIG.ENABLE_TOLLING;
  const hasShifts = user.posIds && user.posIds.length > 0 &&
      poses.filter(pos => user.posIds.indexOf(pos.id) > -1).find(pos => pos.cashierShiftMode);
  useEffect(() => {
    if(!isToll && user.username){
      setIsShiftOpen(anOpenedShift);
      checkActiveCashierShifts().then(({data}) => {
        if (anOpenedShift) {
          if (data && data.id !== anOpenedShift) {
            error.current = gettext('APP.COMMON.ERROR.OTHER_USER_SHIFT_OPEN');
          } else if (!data) {
            getCashierShiftStatusById(anOpenedShift).then(({data}) => {
              if (data && data.shiftStatus === 2) {
                error.current = undefined;
                removeItem('cashier-shift');
                main.current.refresh();
              } else {
                error.current = gettext('APP.COMMON.ERROR.OTHER_USER_SHIFT_OPEN');
              }
            }).catch(handleErrors);
          } else if (anOpenedShift && data && data.id === anOpenedShift) {
            error.current = undefined;
          }
        } else {
          error.current = undefined;
        }
      });
    }

  },[user, anOpenedShift]);
  const menuItems = [
    {
      title: gettext('Покупка на винетка'),
      link: '/buy-vignette',
      access: (roleList([
        'ROLE_USERS',
        'ROLE_C9',
        'ROLE_CENTRAL_WAREHOUSE_ADMIN',
        'ROLE_PARTNER_ADMIN',
        'ROLE_ADMIN',
        'ROLE_CONCORD_ADMIN']) || userHasRole('ROLE_VIGNETTE_USER')) && CONFIG.ENABLE_VIGNETTE === true
    },{
      title: gettext('Маршрутна карта'),
      link: '/buy-route-pass',
      access: (roleList([
        'ROLE_USERS',
        'ROLE_C9',
        'ROLE_CENTRAL_WAREHOUSE_ADMIN',
        'ROLE_PARTNER_ADMIN',
        'ROLE_ADMIN',
        'ROLE_CONCORD_ADMIN']) || userHasRole('ROLE_ROUTE_PASS_USER')) && CONFIG.ENABLE_ROUTE_PASS === true
    },{
      title: gettext('Нарушения'),
      link: '/search-delict-payments',
      access: (userIs('ROLE_ADMIN') || isDelictUser()) && !CONFIG.ENABLE_TOLLING
    },{
      title: gettext('Справки'),
      access: (
          !userIs('ROLE_TOLL_ADMIN') &&
          !userIs('ROLE_KAPSCH_OBU_SERVICE') &&
          !userIs('ROLE_CUSTOMERS_READ') &&
          !userIs('ROLE_CUSTOMERS_WRITE') &&
          !userIs('ROLE_OBU_READ') &&
          !userIs('ROLE_OBU_WRITE') &&
          !userIs('ROLE_UNITS_WRITE') &&
          !userIs('ROLE_UNITS_READ') &&
          !userIs('ROLE_HANDOUT_WRITE') &&
          !userIs('ROLE_NOTIFICATIONS_READ') &&
          !userIs('ROLE_VIEW_INVOICES') &&
          !userIs('ROLE_EDIT_INVOICES') &&
              !userIs('ROLE_DELICTS_READ') &&
              !userIs('ROLE_DELICTS_WRITE')
      ),
      items: [
        {
          title: gettext('Продажби'),
          link: '/inquiry',
          access: (CONFIG.ENABLE_ROUTE_PASS === true || CONFIG.ENABLE_VIGNETTE === true)
        },
        {
          title: gettext('Агрегирани справки'),
          link: '/report',
          access: 'ROLE_ACCOUNTANT'
        },
        {
          title: gettext('Оборотна ведомост'),
          link: '/trial-balance',
          access: (userIs('ROLE_ACCOUNTANT') || userAllowed('ROLE_ADMIN')) && CONFIG.ENABLE_TOLLING === true
        },
        {
          title: gettext('APP.ERP.COMMON.ONGOING_PAYMENTS'),
          link: '/ongoing-payments',
          access: (userIs('ROLE_ACCOUNTANT') || userIs('ROLE_ADMIN')) && CONFIG.ENABLE_TOLLING === true
        }
      ]
    },{
        title: gettext('Банков отчет'),
        access: (roleList(['ROLE_ACCOUNTANT', 'ROLE_ADMIN', 'ROLE_CONCORD_ADMIN'])) && CONFIG.ENABLE_TOLLING === true,
        items: [
          {
            title: gettext('Трансакции'),
            link: '/bank-report',
          },
          {
            title: gettext('Неуспешни трансакции'),
            link: '/bank-report-failed',
          }
        ]
      },{
      title: gettext('Администрация'),
      access: (roleList(['ROLE_KAPSCH_OBU_SERVICE', 'ROLE_ACCOUNTANT']) || userAllowed('ROLE_PARTNER_ADMIN') || userHasRole('ROLE_OBU_READ') || userHasRole('ROLE_CUSTOMERS_READ') || userHasRole('ROLE_NOTIFICATIONS_READ') || userHasRole('ROLE_CUSTOMERS_WRITE') || userHasRole('ROLE_OBU_WRITE') || userHasRole('ROLE_UNITS_WRITE') || userHasRole('ROLE_UNITS_READ') || userHasRole('ROLE_HANDOUT_WRITE') || userHasRole('ROLE_VIEW_INVOICES') || userHasRole('ROLE_EDIT_INVOICES')),
      items: [
        {
          title: gettext('Потребители'),
          link: '/user-management',
          access: (userAllowed('ROLE_PARTNER_ADMIN') && !userIs('ROLE_TOLL_ADMIN'))
        },{
          title: gettext('ОБУ-та'),
          access: ((userIs('ROLE_KAPSCH_OBU_SERVICE') || userAllowed('ROLE_PARTNER_ADMIN')) || (userHasRole('ROLE_OBU_READ')) || userHasRole('ROLE_OBU_WRITE')) && CONFIG.ENABLE_TOLLING === true,
          items: [
            {
              title: gettext('Управление на ОБУ-та'),
              link: '/obu-management',
            },{
              title: gettext('Доставки'),
              link: '/obu-deliveries',
            },{
              title: gettext('Доставки от Капш'),
              link: '/obu-kapsch-deliveries',
              access: (
                  !userIs('ROLE_TOLL_ADMIN') &&
                  !userIs('ROLE_OBU_READ') &&
                  !userIs('ROLE_OBU_WRITE')
              )
            },{
              title: gettext('Склад'),
              link: '/warehouse'
            }
          ]
        },{
          title: gettext('Клиенти'),
          link: '/partner-management',
          access: (userIs('ROLE_ACCOUNTANT') || userAllowed('ROLE_ADMIN') || userHasRole('ROLE_CUSTOMERS_READ') || userHasRole('ROLE_CUSTOMERS_WRITE'))&& CONFIG.ENABLE_TOLLING === true
        },{
          title: gettext('Партньори'),
          link: '/sales-partner-management',
          access: userIs('ROLE_ADMIN')
        },{
          title: gettext('Договори'),
          link: '/contract-management',
          access: (userIs('ROLE_ACCOUNTANT') || userAllowed('ROLE_ADMIN') || (userHasRole('ROLE_CUSTOMERS_READ') || userHasRole('ROLE_CUSTOMERS_WRITE'))) && CONFIG.ENABLE_TOLLING === true
        },{
          title: gettext('Обекти'),
          link: '/pos-management',
          access: (userAllowed('ROLE_PARTNER_ADMIN') && !userIs('ROLE_TOLL_ADMIN'))
        },{
          title: gettext('APP.COMMON.COMMON.SERVICE_FEES'),
          link: '/service-fees',
          access: CONFIG.ENABLE_TOLLING === true
              && hasFeature("SERVICE_FEES")
              && (userHasRole('ROLE_ACCOUNTANT') || userHasRole('ROLE_ADMIN'))
        },{
          title: gettext('APP.COMMON.COMMON.TRUCKS'),
          link: '/vehicle-management',
          access: ((userIs('ROLE_ACCOUNTANT') || userAllowed('ROLE_ADMIN')) || (userHasRole('ROLE_UNITS_READ')) || userHasRole('ROLE_UNITS_WRITE')) && CONFIG.ENABLE_TOLLING === true
        },{
          title: gettext('APP.COMMON.COMMON.NOTIFICATIONS'),
          link: '/notifications',
          access: (userAllowed('ROLE_ADMIN') || userHasRole('ROLE_NOTIFICATIONS_READ')) && CONFIG.ENABLE_TOLLING === true
        },{
          title: gettext('APP.ERP.COMMON.EMAILS'),
          link: '/mass-mailing',
          access: (userHasRole('ROLE_ADMIN') || userHasRole('ROLE_CONCORD_ADMIN')) && CONFIG.ENABLE_TOLLING === true
        },{
          title: gettext('APP.COMMON.COMMON.INVOICES'),
          link: '/invoices',
          access: CONFIG.ENABLE_TOLLING === true
              && hasFeature("INVOICES")
              && (userHasRole('ROLE_ACCOUNTANT')
                  || userHasRole('ROLE_ADMIN')
                  || userHasRole('ROLE_CONCORD_ADMIN')
                  || userHasRole('ROLE_VIEW_INVOICES')
              )
        },
        ...!isToll ? [{
          title: gettext('APP.ERP.COMMON.ANNOUNCEMENTS'),
          link: '/announcements',
          access: userIs('ROLE_ADMIN')
        }] : [],
        ...!isToll ? [{
          title: gettext('Смени'),
          link: '/shifts',
          access: userAllowed('ROLE_PARTNER_ADMIN') && !CONFIG.ENABLE_TOLLING
        }] : [],
      ]
    },{
      title: gettext('APP.ERP.PARTNER.DELICTS'),
      access: CONFIG.ENABLE_TOLLING === true && (userHasRole('ROLE_ADMIN') || userHasRole('ROLE_CONCORD_ADMIN') || userHasRole('ROLE_DELICTS_READ')),
      link: '/delicts',
    }
  ];

  const langItems = LANGUAGES.map(({ lang }) => ({
    props: {
      onClick: () => setLanguage(lang)
    },
    jsx: <LanguageFlag lang={lang} showText/>
  }));

  const openShiftPopupOptions = () => ({
    title: hasShifts && `${gettext('Отваряне на смяна')}`,
    content: (<div key={hasShifts.id} className="panel-edit panel-popup">
      <Shift initialValues={hasShifts} onSave={popupSubmit} />
    </div>)
  })

  const closeShiftPopupOptions = (id) => ({
    title: gettext('Справка за смяната'),
    content: (<div key={hasShifts.id} className="panel-edit panel-popup">
      <div className='shifts-report-wrapper'>
        <div className='shifts-report' title={gettext('Детайлна справка')}>
          <span className="icon icon-book icon-shifts"
            onClick={() => exportDetailedShipmentsCsv(id)} />
          {`${gettext('Свали детайлна справка за смяна')} (csv)`}
        </div>
        <div className='shifts-report' title={gettext('Обобщена справка')}>
          <span className="icon icon-file-text-o icon-shifts"
            onClick={() => exportSummarizedShipmentsExcel(id)} />
          {`${gettext('Свали обобщена справка за смяна')} (excel)`}
        </div>
      </div>
    </div>)
  })

  return (
    <div className="site-header headroom headroom--top">
      <div className="site-header-main" ref={main}>
        <div className="row wide">
          <div className="column small-12">
            <div className="site-header-inner">
              <h1 className="site-logo"><Link to="/">{gettext('DigiToll')}</Link></h1>

              <nav className="site-navigation site-navigation-dropdown" id="site-navigation">
                <div className="menu-icon menu-icon-lang" onClick={() => {
                  setMobileNavOpen(false);
                  setMobileLangOpen(!mobileLangOpen);
                }}>
                  <LanguageFlag />
                </div>
                {
                  user.id && (
                      <>
                        <div className="menu-icon menu-icon-nav icon icon-bars icon-primary" onClick={() => {
                          setMobileLangOpen(false);
                          setMobileNavOpen(!mobileNavOpen);
                        }}/>
                        <DesktopMenu items={menuItems} />
                      </>
                  )
                }
              </nav>

              { !userIs('ROLE_RIA') && (CONFIG.ENABLE_ROUTE_PASS || CONFIG.ENABLE_VIGNETTE) && <CartPreview /> }
              <LanguagePicker/>

              { user.id && <UserDropdown /> }

              { hasShifts &&
                <div className="open-shift-link"
                  onClick={() => {
                    error.current
                      ? popupMessageOpen(gettext('Грешка'), error.current)
                      : isShiftOpen
                        ? popupConfirmOpen(gettext('APP.COMMON.COMMON.CONFIRM'), gettext('Сигурни ли сте, че искате да затворите смяната?'))
                          .then(() => {
                            closeShift(anOpenedShift)
                              .then(({data}) => {
                                removeItem('cashier-shift');
                                setIsShiftOpen(null);
                                popupOpen(closeShiftPopupOptions(data.id));
                              })
                              .catch(err => popupMessageOpen(gettext('Грешка'), handleErrorMessage(err)))
                          })
                        : popupOpen(openShiftPopupOptions())
                  }}>
                  {gettext(isShiftOpen ? 'Затвори смяна' : 'Отвори смяна')}
                </div>
              }

            </div>
          </div>
        </div>

        <MobileMenu open={mobileLangOpen} setOpen={setMobileLangOpen} items={langItems} />

        { user.id && <MobileMenu open={mobileNavOpen} setOpen={setMobileNavOpen} items={menuItems} /> }

      </div>
    </div>
  );
};
