// this function finds a value from a deep object based on its string representation
import axios from "axios";
import React from "react";

export function resolveObj(str, obj){
  return str && obj ? str.split('.').reduce((o,i)=> o ? o[i] : false, obj) : null;
}

// for splitting an array into chunks of predefined size
export function chunkArray (arr, n){
  return Array.from(Array(Math.ceil(arr.length/n)), (_,i)=>arr.slice(i*n,i*n+n));
}

// gets property or result of a function
export function getProperty (prop, data) {
  return typeof prop === "function" ? prop(data) : prop;
};

// converting the data format of contacts inside customers and contracts
export function convertContacts (values, fromServer=false) {
  return fromServer ?
  {
    ...values,
    ...(values.contacts && {
      contacts: values.contacts.map(
        ({contactEmails, contactNumbers, ...others}) => ({
          ...others,
          email: (contactEmails && contactEmails.length) ? contactEmails[0].emailAddress : '',
          code: (contactNumbers && contactNumbers.length) ? contactNumbers[0].areaCode : '',
          phone: (contactNumbers && contactNumbers.length) ? contactNumbers[0].localNumber : '',
          country: (contactNumbers && contactNumbers.length) ? contactNumbers[0].countryCode : '',
          additionalEmails: (contactEmails && contactEmails.length > 1) ? contactEmails.slice(1) : null,
          additionalNumbers: (contactNumbers && contactNumbers.length > 1) ? contactNumbers.slice(1) : null,
        })
      ),
        hasMstsToll: values.hasMstsToll ? values.hasMstsToll : false
    })
  }
  :
  {
    ...values,
    contacts: values.contacts.map(
      ({email, code, phone, country, additionalEmails, additionalNumbers, ...others}) => ({
        ...others,
        contactEmails: additionalEmails ?
          [
            {emailAddress: email, isPreferredContactMean: true, contactMeansClassId: 1},
            ...additionalEmails
          ]
          :
          [
            {emailAddress: email, isPreferredContactMean: true, contactMeansClassId: 1}
          ],
        contactNumbers: additionalNumbers ?
          [
            {
              contactMeansClassId: 1,
              contactNumberTypeId: 1,
              areaCode: code, localNumber: phone,
              isPreferredContactMean: true,
              countryCode: 'BG'/*country*/
            },
            ...additionalNumbers
          ]
          :
          [
            {
              contactMeansClassId: 1,
              contactNumberTypeId: 1,
              areaCode: code, localNumber: phone,
              isPreferredContactMean: true,
              countryCode: 'BG'/*country*/
            }
          ],
      })
    )
  }
};

//Formats filesize from bytes to kB, MB or GB
export function formatFileSize(filesize){
  if(filesize >= 2**30){
    return Math.round(filesize / (2**30) * 10) / 10 + " GB";
  } else if(filesize >= 2**20){
    return Math.round(filesize / (2**20) * 10) / 10 + " MB";
  } else if(filesize >= 2**10){
    return Math.round(filesize / (2**10) * 10) / 10 + " KB";
  } else {
    return filesize + " B";
  }
}

let cancelTokens = [];
export function getWithCancel(url, apiParams) {
    if (cancelTokens[url]) {
        cancelTokens[url].cancel("Operation canceled due to new request.")
    }
    cancelTokens[url] = axios.CancelToken.source();
    let params = {...apiParams, cancelToken: cancelTokens[url].token};
    return axios.get(url, params);
}

export function colorizeText(text, color) {
    return <span style={{color}}>{text}</span>;
}

export function parseBooleanValue(value) {
    return value === 'true';
}
