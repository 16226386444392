import axios from 'axios';
import { BASE_URL } from '../util/constants';

const orderedCountries = [
    "BG",
    "TR",
    "RO",
    "IR",
    "UA",
    "GR",
    "RS",
    "MK",
];

export function getAllCountries() {
    return axios.get(`${BASE_URL}/countries`).then(({ data }) => {

        data.sort((a, b) => {
            return orderedCountries.indexOf(a.countryCode) > -1
            ? -1
            : orderedCountries.indexOf(b.countryCode) > -1
                ? 1
                : a.countryName.toLowerCase() > b.countryName.toLowerCase()
                    ? 1
                    : b.countryName.toLowerCase() > a.countryName.toLowerCase()
                        ? -1 : 0
        });

        const arr = data.splice(0, orderedCountries.length);
        const result = [];
        arr.map(country => result[orderedCountries.indexOf(country.countryCode)] = country);

        return [...result, ...data];
    });
}